import { TCustomer } from '@ws/shared/types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Loader } from '../../../components/Loader';
import {
  // TelegramLogin,
  TelegramUser,
} from '../../../components/TelegramLoginButton';
import { getResourceManager } from '../../../resources';
import { AuthLogin } from '../AuthLogin';
import { AuthSignUp } from '../AuthSignUp';

import styles from './AuthPanel.module.css';

const AUTH_STATUS_CHANGE_EVENT = 'AUTH_STATUS_CHANGE_EVENT';

const AUTH_STATUS = {
  AUTHED: 'AUTHED',
  UNAUTHED: 'UNAUTHED',
  LOADING: 'LOADING',
};

const AUTH_SECTION = {
  LOGIN: 'LOGIN',
  SIGN_UP: 'SIGN_UP',
};

function sendMessage(status: string, origin = '') {
  if (origin) {
    console.log('origin', origin);
    window.top?.postMessage({ event: AUTH_STATUS_CHANGE_EVENT, status }, origin);
  }
}

export interface IAuthPanelProps {
  openerOrigin?: string;
}

export function AuthPanel({ openerOrigin }: IAuthPanelProps) {
  const [status, setStatus] = useState<string>(AUTH_STATUS.LOADING);
  const [selectedSection, setSelectedSection] = useState<string>(AUTH_SECTION.LOGIN);
  const [customer, setCustomer] = useState<TCustomer | null>(null);

  const [telegramUser] = useState<TelegramUser | null>(null);

  function resetAuthSection() {
    setSelectedSection(AUTH_SECTION.LOGIN);
  }

  function handleSignUp() {
    resetAuthSection();
  }

  function handleLogin() {
    sendMessage(AUTH_STATUS.AUTHED, openerOrigin);
    checkUser();
  }

  async function handleLogoutClick() {
    setStatus(AUTH_STATUS.LOADING);
    resetAuthSection();

    const resourceManager = getResourceManager();
    const { isOk } = await resourceManager.AuthService.logout();
    if (isOk) {
      toast.success('Successfully logged out!');
    } else {
      toast.error('Something went wrong!');
    }

    setTimeout(() => {
      checkUser();
    }, 500);

    sendMessage(AUTH_STATUS.UNAUTHED, openerOrigin);
  }

  async function checkUser() {
    const resourceManager = getResourceManager();
    const { isOk, customer: customerData } = await resourceManager.UserService.getInfo();

    if (isOk && customerData) {
      setStatus(AUTH_STATUS.AUTHED);
      setCustomer(customerData || null);
    } else {
      setStatus(AUTH_STATUS.UNAUTHED);
      setCustomer(null);
    }
  }

  useEffect(() => {
    checkUser();
  }, []);

  // function handleTelegramAuthData(user: TelegramUser) {
  //   console.log('TelegramUser', user);
  //   setTelegramUser(user);
  // }

  if (status === AUTH_STATUS.LOADING) {
    return (
      <div className={styles['auth-panel']}>
        <div className={styles['panel-message']}>
          <Loader className={styles['loader']} />
        </div>
      </div>
    );
  }

  if (status === AUTH_STATUS.AUTHED) {
    return (
      <div className={styles['auth-panel']}>
        <div className={styles['panel-message']}>
          {`Привет, ${customer?.first_name || customer?.login}!`}
          <span>
            <button className={styles['link']} onClick={handleLogoutClick}>
              Выйти
            </button>
            .
          </span>
        </div>
      </div>
    );
  }

  if (status === AUTH_STATUS.UNAUTHED) {
    return (
      <div className={styles['auth-panel']}>
        {selectedSection === AUTH_SECTION.LOGIN && (
          <>
            <AuthLogin telegramUserData={telegramUser} onLogin={handleLogin} />

            {/*
              <div>
                Или:
                <TelegramLogin
                  requestAccess
                  botName="writer_studio_test_bot"
                  dataOnAuth={handleTelegramAuthData}
                />
              </div>
            */}

            <div className={styles['panel-message']}>
              Еще нет аккаунта?
              <button
                className={styles['link']}
                onClick={() => setSelectedSection(AUTH_SECTION.SIGN_UP)}
              >
                Зарегистрироваться
              </button>
              .
            </div>
          </>
        )}

        {selectedSection === AUTH_SECTION.SIGN_UP && (
          <>
            <AuthSignUp
              telegramUserData={telegramUser}
              onSignUp={handleSignUp}
              reset={resetAuthSection}
            />

            {/*
            <div>
              Или:
              <TelegramLogin
                requestAccess
                botName="writer_studio_test_bot"
                dataOnAuth={handleTelegramAuthData}
              />
            </div>
            */}

            <div className={styles['panel-message']}>
              Уже есть аккаунт?
              <span>
                <button
                  className={styles['link']}
                  onClick={() => setSelectedSection(AUTH_SECTION.LOGIN)}
                >
                  Login
                </button>
                .
              </span>
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
}
