import { useState } from 'react';

import { Page } from '../../components/Page';

import styles from './AuthPage.module.css';
import { AuthPanel } from './AuthPanel';

const APP_MODE: { [key: string]: string } = {
  STANDALONE: 'STANDALONE',
  EMBEDDED: 'EMBEDDED',
};

const ACCEPTED_ORIGINS = [
  'http://localhost:3030',
  'https://dev.writerstudio.co',
  'https://textator.dev.writerstudio.co',
  'https://previews.dev.writerstudio.co',
  'https://writerstudio.co',
];

function checkParams() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const modeParam = urlParams.get('mode') || '';
  const serviceParam = urlParams.get('service') || '';
  const coordsParam = urlParams.get('coords') || '';

  return {
    mode: APP_MODE[modeParam] || APP_MODE.STANDALONE,
    service: serviceParam,
    coords: coordsParam,
  };
}

export function AuthPage() {
  const params = checkParams();
  const [persistedParams] = useState(params);

  const origin = ACCEPTED_ORIGINS.includes(persistedParams.coords) ? persistedParams.coords : '';
  console.log('ORIGIN', origin);

  return (
    <Page className={styles['auth-page']}>
      {persistedParams.mode === APP_MODE.STANDALONE && (
        <div className={styles['auth-page__logo']}>WriterStudio</div>
      )}

      <AuthPanel openerOrigin={origin} />
    </Page>
  );
}
