import { classes } from 'classifizer';

import styles from './Page.module.css';

export interface IPageProps {
  children: any;
  className?: string;
}

export function Page({ className, children }: IPageProps) {
  return <div className={classes(styles['page'], className)}>{children}</div>;
}
