import { Input, Button } from '@ws/shared/components';
import { Eye20, EyeOff20 } from '@ws/shared/icons';
import { IAuthLoginUser } from '@ws/shared/resources';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Form } from '../../../components/Form';
import { TelegramUser } from '../../../components/TelegramLoginButton';
import { getResourceManager } from '../../../resources';

export interface IAuthLoginProps {
  telegramUserData?: TelegramUser | null;

  onLogin(): void;
}

export function AuthLogin({ onLogin, telegramUserData }: IAuthLoginProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState('password');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: IAuthLoginUser) => {
    setIsLoading(true);
    console.log('data', data);

    const resourceManager = getResourceManager();
    const { isOk } = await resourceManager.AuthService.login({
      telegramId: telegramUserData?.id,
      login: data.login,
      password: data.password,
    });

    if (isOk) {
      onLogin();
      toast.success('Успешно вошли в систему!');
    } else {
      toast.error('Что-то пошло не так!');
    }
    setIsLoading(false);
  };

  const setInputType = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  };

  return (
    <Form
      isLoading={isLoading}
      formTitle="Добро пожаловать"
      buttonTitle="Войти"
      onSubmit={handleSubmit(onSubmit as any)}
      error={Boolean(errors.login || errors.password)}
    >
      <Input
        error={Boolean(errors.login)}
        label="Логин или почта"
        captionText={errors.login && 'Пожалуйста, введите действующий логин'}
        {...register('login', { required: !telegramUserData })}
      />
      <Input
        error={Boolean(errors.password)}
        label="Пароль"
        type={passwordInputType}
        endIcon={
          <Button
            icon={passwordInputType === 'password' ? <Eye20 /> : <EyeOff20 />}
            variant="shapeless"
            onClick={setInputType}
          />
        }
        captionText={errors.password && 'Неверный пароль'}
        {...register('password', { required: !telegramUserData })}
      />
    </Form>
  );
}
