import Joi from 'joi';

export type TConfig = {
  apiUri: string;
};

const ConfigSchema = Joi.object({
  apiUri: Joi.string().required(),
});

const defaultConfig = {
  apiUri: 'http://localhost:4040',
};

const __config: TConfig = defaultConfig;

export function initConfig(config: TConfig | null) {
  console.log('==> initConfig');
  if (!config) {
    throw Error('No config file');
  }

  const validationResults = ConfigSchema.validate(config);
  if (validationResults.error) {
    console.log('Config error', validationResults.error);
    throw Error('Config file is NOT VALID!');
  }

  __config.apiUri = validationResults.value.apiUri;
}

export function getConfig() {
  return __config;
}
