import { Fetcher } from '@ws/shared/utils';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './components/App';
import './index.scss';
import { initConfig, TConfig } from './config/config';
import { initResources } from './resources';

function fetchConfig() {
  const fetcher = new Fetcher();
  return fetcher
    .fetch<TConfig>({ url: '/config/config.json', isExternal: true })
    .then((fileData) => {
      initConfig(fileData.data);
    });
}

function renderApp(Component: () => JSX.Element) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <StrictMode>
      <Component />
    </StrictMode>,
  );
}

function handleUnAuth() {
  // TODO
}

async function InitApp() {
  await fetchConfig();

  initResources(handleUnAuth);

  renderApp(App);
}

InitApp();
