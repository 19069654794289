import { toast, ToastContainer } from 'react-toastify';

import { AuthPage } from '../../pages';

import '@ws/shared/src/styles/index.css';
import styles from './App.module.css';
import 'react-toastify/dist/ReactToastify.css';

export function App() {
  return (
    <div className={styles['app']}>
      <AuthPage />

      <ToastContainer
        autoClose={3000}
        draggable={true}
        position={toast.POSITION.BOTTOM_RIGHT}
        className={styles['custom-toaster']}
      />
    </div>
  );
}
