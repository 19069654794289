import { Button } from '@ws/shared/components';

import { Loader } from '../Loader';

import styles from './Form.module.css';

export interface IFormProps {
  onSubmit(): void;
  isLoading?: boolean;
  formTitle?: string;
  buttonTitle: string;
  children?: any;
  error?: boolean;
}

export function Form({ formTitle, buttonTitle, isLoading, onSubmit, error, children }: IFormProps) {
  return (
    <form className={styles['form']}>
      {formTitle && <h1 className={styles['form-title']}>{formTitle}</h1>}

      <div className={styles['children-container']}>{children}</div>

      <button className={styles['forgot-button']} type="button">
        Забыли пароль?
      </button>

      {isLoading ? (
        <Loader />
      ) : (
        <Button
          onClick={onSubmit}
          className={styles['submit-button']}
          type="button"
          disabled={error}
        >
          {buttonTitle}
        </Button>
      )}
    </form>
  );
}
