import { Input } from '@ws/shared/components';
import { IAuthSignUpUser } from '@ws/shared/resources';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Form } from '../../../components/Form';
import { TelegramUser } from '../../../components/TelegramLoginButton';
import { getResourceManager } from '../../../resources';

export interface IAuthSignUpProps {
  telegramUserData?: TelegramUser | null;

  onSignUp(): void;

  reset(): void;
}

const defaultValues = {
  email: '',
  login: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  secondName: '',
};

function buildDefaultValues(telegramUser?: TelegramUser | null) {
  let draft = {
    ...defaultValues,
  };

  if (!telegramUser) {
    return draft;
  }

  draft = {
    ...draft,
    login: telegramUser.username,
    firstName: telegramUser.first_name,
    secondName: telegramUser.last_name,
  };

  return draft;
}

export function AuthSignUp({ reset, onSignUp, telegramUserData }: IAuthSignUpProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: buildDefaultValues(telegramUserData),
  });

  const onSubmit = async (data: IAuthSignUpUser) => {
    setIsLoading(true);
    console.log('data', data);

    const resourceManager = getResourceManager();
    const { isOk } = await resourceManager.AuthService.signUp({ ...data });

    if (isOk) {
      onSignUp();

      toast.success('Successfully signed up!');
      reset();
    } else {
      toast.error('Something went wrong!');
    }

    setIsLoading(false);
  };

  return (
    <Form
      isLoading={isLoading}
      formTitle="Присоединяйтесь!"
      buttonTitle="Зарегистрироваться"
      onSubmit={handleSubmit(onSubmit as any)}
    >
      <Input
        error={Boolean(errors.email)}
        label="Email"
        required
        captionText={errors.email && 'Пожалуйста, введите почту'}
        {...register('email', { required: true })}
      />

      <Input
        error={Boolean(errors.login)}
        label="Login"
        required
        captionText={errors.login && 'Пожалуйста, введите логин'}
        {...register('login', { required: true })}
      />

      <Input
        error={Boolean(errors.password)}
        label="Пароль"
        required
        captionText={errors.password && 'Пожалуйста, введите пароль'}
        {...register('password', { required: true })}
      />

      <Input
        error={Boolean(errors.confirmPassword)}
        label="Пароль ещё раз"
        required
        captionText={errors.confirmPassword && 'Пожалуйста, подтвердите пароль'}
        {...register('confirmPassword', { required: true })}
      />

      <Input label="Имя" {...register('firstName')} />
      <Input label="Фамилия" {...register('secondName')} />
    </Form>
  );
}
