import { AuthService, UserService } from '@ws/shared/resources';
import { Fetcher, buildMcsUrl } from '@ws/shared/utils';
import { toast } from 'react-toastify';

import { getConfig } from '../config/config';

type TResourceManager = {
  AuthService: AuthService;
  UserService: UserService;
};

const __manager: TResourceManager = {} as TResourceManager;

export function initResources(handleUnAuth: () => void) {
  const fetcher = new Fetcher(getConfig().apiUri, handleUnAuth, buildMcsUrl);

  (__manager as TResourceManager).AuthService = new AuthService(fetcher, toast);
  (__manager as TResourceManager).UserService = new UserService(fetcher, toast);
}

export function getResourceManager() {
  if (!__manager) {
    throw new Error('Resource manager was not initialized!');
  }

  return __manager;
}
